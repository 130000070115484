<template>
  <div
    class="d-flex justify-content-center body py-5 px-2"
    v-if="fatura"
    style="overflow: hidden"
  >
    <section class="fatura col-md-10">
      <div class="row">
        <div class="col-md-4 text-center text-md-left mb-4 mb-md-0">
          <a href="/"
            ><img :src="logo" alt="logo" :title="site_name" width="200"
          /></a>
        </div>
        <div class="col-md-8 text-md-right">
          <h3>
            FATURA <small class="text-muted">#{{ fatura.codigo }}</small>
          </h3>
          <p>
            <span
              class="badge px-2 py-1"
              v-bind:class="{
                'badge-warning': ['pendente', 'aguardando pagamento'].includes(
                  fatura.status
                ),
                'badge-danger': ['cancelada', 'vencida'].includes(
                  fatura.status
                ),
                'badge-success': ['pago', 'pago maior', 'pago menor'].includes(
                  fatura.status
                ),
              }"
              >{{ fatura.status | uppercase }}</span
            >
          </p>
        </div>
      </div>

      <div class="row row-info">
        <div class="cliente col-md-6 d-flex flex-column">
          <div class="d-flex flex-column">
            <label>CLIENTE</label>
            <p>{{ fatura.cliente.nomeCliente | uppercase }}</p>
          </div>

          <div class="d-flex flex-column">
            <label>CPF/CNPJ</label>
            <p>{{ fatura.cliente.cpfCpnj | uppercase }}</p>
          </div>

          <div class="d-flex flex-column">
            <label>ENDEREÇO</label>
            <p>
              {{ fatura.cliente.logradouroCliente | uppercase }},
              {{ fatura.cliente.numeroCliente | uppercase }},
              {{ fatura.cliente.bairroCliente | uppercase }},
              {{ fatura.cliente.complementoCliente | uppercase }},
              {{ fatura.cliente.cidadeCliente | uppercase }},
              {{ fatura.cliente.estadoCliente | uppercase }},
              <br />
              CEP:{{ fatura.cliente.cepCliente | uppercase }}
            </p>
          </div>

          <div class="d-flex flex-column">
            <label>LEILÃO</label>
            <p>{{ fatura.nomeLeilao | uppercase }}</p>
          </div>
        </div>

        <div class="cedente col-md-6 d-none d-md-block flex-column">
          <div class="d-flex flex-column">
            <label class="txt-end">CEDENTE</label>
            <p class="txt-end">
              {{ fatura.empresa.nomeEmpresa | uppercase }}
            </p>
          </div>
          <div class="d-flex flex-column">
            <label class="txt-end">CPF/CNPJ</label>
            <p class="txt-end">
              {{ fatura.empresa.cpfCnpjEmpresa | uppercase }}
            </p>
          </div>
          <div class="d-flex flex-column">
            <label class="txt-end">ENDEREÇO</label>
            <p class="txt-end">
              {{ fatura.empresa.logradouroEmpresa | uppercase }},
              {{ fatura.empresa.numeroEmpresa | uppercase }},
              {{ fatura.empresa.bairroEmpresa | uppercase }},
              {{ fatura.empresa.complementoEmpresa | uppercase }},
              {{ fatura.empresa.cidadeEmpresa | uppercase }},
              {{ fatura.empresa.estadoEmpresa | uppercase }},

              <br />
              CEP:{{ fatura.empresa.cepEmpresa | uppercase }}
            </p>
          </div>
        </div>
      </div>

      <div class="row py-2">
        <div class="col-6 align-self-center">
          <h5>DETALHES DA FATURA</h5>
        </div>
        <div class="col-6 d-flex text-right justify-content-center flex-column">
          <label class="text-muted pb-2">VENCIMENTO</label>
          <h5>{{ dataVencimento }}</h5>
        </div>
      </div>

      <div class="row">
        <table class="table table-sm">
          <thead>
            <tr>
              <th class="text-muted"><small>DESCRIÇÃO</small></th>
              <th class="text-right text-muted" width="200">
                <small>VALOR</small>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in itensFatura" :key="index">
              <td>
                <small> {{ item.descricao }}</small>
              </td>
              <td class="text-right text-nowrap">
                {{
                  item.valor
                    | currency('R$', 2, {
                      spaceBetweenAmountAndSymbol: true,
                      decimalSeparator: ',',
                      thousandsSeparator: '.',
                    })
                }}
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <th class="text-right">Subtotal</th>
              <th class="text-right text-nowrap">
                {{
                  fatura.valorTotal
                    | currency('R$', 2, {
                      spaceBetweenAmountAndSymbol: true,
                      decimalSeparator: ',',
                      thousandsSeparator: '.',
                    })
                }}
              </th>
            </tr>
          </tfoot>
        </table>
      </div>

      <div
        v-if="
          (fatura.status == 'pendente' && !fatura.pagamento) ||
          (fatura.status == 'pendente' &&
            fatura.pagamento &&
            fatura.pagamento.status == 'rejeitado')
        "
      >
        <div class="row row-title-pagamento">
          <div class="col-12 py-5">
            <h5>PAGAMENTO DA FATURA</h5>
            <label
              >Efetue o pagamento pela internet com segurança ou em qualquer
              agencia bancária. Escolha uma das opções de pagamento
              abaixo</label
            >
          </div>
        </div>

        <div class="row">
          <ul class="nav nav-tabs col-12">
            <li
              class="nav-item col-6"
              v-if="fatura.formasPagamento.includes('Cartão de Crédito')"
            >
              <a
                class="nav-link d-flex justify-content-center"
                v-bind:class="{
                  active: cartaoBoolean,
                  inactive: boletoBoolean,
                }"
                @click="changeOpcaoPagamento('cartao')"
                aria-current="page"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  class="bi bi-credit-card-fill"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v1H0V4zm0 3v5a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7H0zm3 2h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-1a1 1 0 0 1 1-1z"
                  />
                </svg>
                &nbsp;<b>Crédito</b>
              </a>
            </li>
            <li
              class="nav-item col-6"
              v-if="fatura.formasPagamento.includes('Boleto')"
            >
              <a
                class="nav-link d-flex justify-content-center"
                v-bind:class="{
                  active: boletoBoolean,
                  inactive: cartaoBoolean,
                }"
                @click="changeOpcaoPagamento('boleto')"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  class="bi bi-file-earmark-text-fill"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zM4.5 9a.5.5 0 0 1 0-1h7a.5.5 0 0 1 0 1h-7zM4 10.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm.5 2.5a.5.5 0 0 1 0-1h4a.5.5 0 0 1 0 1h-4z"
                  />
                </svg>
                &nbsp;<b>Boleto</b>
              </a>
            </li>
          </ul>

          <section class="col-12 pt-5" v-show="cartaoBoolean">
            <!-- <div class="alert alert-info" role="alert">
              Solicite seu link de pagamento por email, informando o número do
              lote e quantidade de parcelas desejada. <br />
              Veja a simulação das parcelas abaixo.
            </div>
            <h4>Simulação das parcelas</h4>
            <ul class="list-group">
              <li
                class="list-group-item"
                v-for="(item, index) in fatura.opcoesParcelamento"
                :key="index"
              >
                {{ item.parcela }}x de
                {{
                  item.valorMensal
                    | currency("R$", 2, {
                      spaceBetweenAmountAndSymbol: true,
                      decimalSeparator: ",",
                      thousandsSeparator: ".",
                    })
                }}
              </li>
            </ul> -->

            <!-- <option
                      v-for="(item, index) in fatura.opcoesParcelamento"
                      :key="index"
                      :value="item.parcela"
                    >
                      {{ item.parcela }}x de
                      {{
                        item.valorMensal
                          | currency("R$", 2, {
                            spaceBetweenAmountAndSymbol: true,
                            decimalSeparator: ",",
                            thousandsSeparator: ".",
                          })
                      }}
                    </option> -->
            <form id="form-checkout">
              <input id="token" name="token" type="hidden" />
              <div class="row">
                <div class="form-group mb-30 col-6">
                  <label for="form-checkout__cardNumber" id="nrCard"
                    >Número do Cartão
                    <select id="form-checkout__issuer" disabled></select
                  ></label>
                  <div
                    id="form-checkout__cardNumber"
                    class="form-control"
                  ></div>
                </div>
                <div class="form-group mb-30 col-6">
                  <label for="form-checkout__cardholderName"
                    >Titular do Cartão</label
                  >
                  <input
                    type="text"
                    id="form-checkout__cardholderName"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="row">
                <div class="form-group mb-30 col-6">
                  <label for="form-checkout__expirationDate">Validade</label>
                  <div
                    id="form-checkout__expirationDate"
                    class="form-control"
                  ></div>
                </div>
                <div class="form-group mb-30 col-6">
                  <label for="form-checkout__expirationDate"
                    >Código de Segurança</label
                  >
                  <div
                    id="form-checkout__securityCode"
                    class="form-control"
                  ></div>
                </div>
              </div>
              <div class="row">
                <div class="form-group mb-30 col-6">
                  <label for="form-checkout__identificationType"
                    >Documento</label
                  >
                  <select
                    id="form-checkout__identificationType"
                    class="form-control"
                  ></select>
                </div>
                <div class="form-group mb-30 col-6">
                  <label for="form-checkout__identificationNumber"
                    >Número do documento</label
                  >
                  <input
                    type="text"
                    id="form-checkout__identificationNumber"
                    class="form-control"
                  />
                </div>
              </div>

              <div class="row">
                <div class="form-group mb-30 col-6">
                  <label for="form-checkout__cardholderEmail">E-mail</label>
                  <input
                    type="email"
                    id="form-checkout__cardholderEmail"
                    class="form-control"
                  />
                </div>
                <div class="form-group mb-30 col-6">
                  <label for="parcelas">Parcelas</label>
                  <select
                    id="parcelas"
                    @change="mudarParcela()"
                    v-model="cartao.parcela"
                    placeholder="Parcela"
                    class="form-control"
                  >
                    <option
                      v-for="(item, index) in fatura.opcoesParcelamento"
                      :key="index"
                      :value="item.parcela"
                    >
                      {{ item.parcela }}x de
                      {{
                        item.valorMensal
                          | currency('R$', 2, {
                            spaceBetweenAmountAndSymbol: true,
                            decimalSeparator: ',',
                            thousandsSeparator: '.',
                          })
                      }}
                    </option>
                  </select>
                </div>
              </div>

              <div
                class="alert alert-danger"
                role="alert"
                v-if="mpErros.length > 0"
              >
                <h6 class="alert-heading">Verifique os erros!</h6>
                <hr />
                <ol>
                  <li
                    class="mb-0"
                    v-for="erro in mpErroArray"
                    :key="erro.codigo"
                  >
                    {{ erro.mensagem }}
                  </li>
                </ol>
              </div>

              <div
                class="alert"
                :class="pagamentoStatus"
                role="alert"
                v-if="pagamentoStatus"
              >
                {{ pagamentoResponse.mensagem }}
              </div>

              <select id="form-checkout__installments" hidden></select>
              <div class="row">
                <table class="table table-sm">
                  <thead>
                    <tr>
                      <th class="text-right text-muted">Taxa cartão</th>
                      <th class="text-right text-muted" width="200">
                        {{
                          this.valorTaxaCartao
                            | currency('R$', 2, {
                              spaceBetweenAmountAndSymbol: true,
                              decimalSeparator: ',',
                              thousandsSeparator: '.',
                            })
                        }}
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td class="text-right"><b>Total</b></td>
                      <td class="text-right text-nowrap">
                        <b>
                          {{
                            this.valorTotalCartao
                              | currency('R$', 2, {
                                spaceBetweenAmountAndSymbol: true,
                                decimalSeparator: ',',
                                thousandsSeparator: '.',
                              })
                          }}
                        </b>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="row">
                <div class="col-12 text-center">
                  <button
                    class="btn-pagar"
                    type="submit"
                    id="form-checkout__submit"
                    :disabled="enviando"
                  >
                    <span v-if="!enviando">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        class="bi bi-credit-card"
                        viewBox="0 0 16 16"
                      >
                        <path
                          d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2-1a1 1 0 0 0-1 1v1h14V4a1 1 0 0 0-1-1H2zm13 4H1v5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V7z"
                        />
                        <path
                          d="M2 10a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-1z"
                        />
                      </svg>
                      Pagar com cartão
                    </span>
                    <span v-else>
                      <i class="fa fa-spinner fa-spin"></i>&nbsp; Enviando...
                    </span>
                  </button>
                </div>
              </div>
            </form>
          </section>
          <div v-show="boletoBoolean" class="w-100 text-center">
            <div class="boleto">
              <iframe :src="fatura.boleto.url" frameborder="0"></iframe>
            </div>
            <div class="row">
              <div class="col-12">
                <table class="table table-sm">
                  <thead>
                    <tr>
                      <th class="text-right text-muted">Taxa Boleto</th>
                      <th class="text-right text-muted" width="200">
                        {{
                          fatura.boleto.taxa
                            | currency('R$', 2, {
                              spaceBetweenAmountAndSymbol: true,
                              decimalSeparator: ',',
                              thousandsSeparator: '.',
                            })
                        }}
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td class="text-right"><b>Total</b></td>
                      <td class="text-right text-nowrap">
                        <b>
                          {{
                            (fatura.valorTotal + fatura.boleto.taxa)
                              | currency('R$', 2, {
                                spaceBetweenAmountAndSymbol: true,
                                decimalSeparator: ',',
                                thousandsSeparator: '.',
                              })
                          }}
                        </b>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <button @click="download" class="btn-pagar">
              <i class="fa fa-download"></i>nbsp;Baixar Boleto
            </button>
          </div>
        </div>
      </div>
      <div
        v-else-if="fatura.pagamento && fatura.pagamento.status == 'pendente'"
      >
        <div class="row py-2">
          <div class="col-12 align-self-center">
            <h5>DETALHES DO PAGAMENTO</h5>
          </div>
        </div>
        <div class="alert alert-warning" role="alert">
          O pagamento está sendo processado pela operadora do seu cartão,
          aguarde.
        </div>
      </div>
      <div v-else-if="fatura.pagamento">
        <div class="row py-2">
          <div class="col-12 align-self-center">
            <h5>DETALHES DO PAGAMENTO</h5>
          </div>
        </div>
        <div class="row">
          <table class="table table-sm table-striped">
            <thead>
              <tr>
                <th class="text-muted"><small>DESCRIÇÃO</small></th>
                <th class="text-right text-muted" width="200"></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <small> Data do Pagamento</small>
                </td>
                <td class="text-right text-nowrap">
                  {{ fatura.pagamento.dataPagamento | formatDataHora }}
                </td>
              </tr>

              <tr>
                <td>
                  <small> Forma de Pagamento</small>
                </td>
                <td class="text-right text-nowrap">
                  {{ fatura.pagamento.formaPagamento }}
                </td>
              </tr>

              <tr v-if="fatura.pagamento.formaPagamento == 'Cartão de Crédito'">
                <td>
                  <small> Código de Autorização</small>
                </td>
                <td class="text-right text-nowrap">
                  {{ fatura.pagamento.codigoAutorizacao }}
                </td>
              </tr>

              <tr>
                <td>
                  <small>Taxa</small>
                </td>
                <td class="text-right text-nowrap">
                  {{
                    fatura.pagamento.taxa
                      | currency('R$', 2, {
                        spaceBetweenAmountAndSymbol: true,
                        decimalSeparator: ',',
                        thousandsSeparator: '.',
                      })
                  }}
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th class="text-right">Total Pago</th>
                <th class="text-right text-nowrap">
                  {{
                    fatura.pagamento.valorPago
                      | currency('R$', 2, {
                        spaceBetweenAmountAndSymbol: true,
                        decimalSeparator: ',',
                        thousandsSeparator: '.',
                      })
                  }}
                </th>
              </tr>
            </tfoot>
          </table>
        </div>
        <div
          class="alert"
          :class="pagamentoStatus"
          role="alert"
          v-if="pagamentoStatus"
        >
          {{ pagamentoResponse.mensagem }}
        </div>
      </div>
    </section>
  </div>
</template>

<script>
/* eslint-disable */
import http from '@/service/http/http';
import { DOWNLOAD_BOLETO_URL } from '@/config/config';
import { mapActions } from 'vuex';
import moment from 'moment';
import { saveAs } from 'file-saver';
import mpErros from '@/data/mercadoPagoErros.json';
moment.locale('pt-br');
export default {
  name: 'faturas',
  data() {
    return {
      fatura: '',
      faturaToken: '',
      itensFatura: '',
      dataVencimento: '',
      valorTotal: 0,
      valores: '',
      valorTaxaCartao: 0,
      valorTotalCartao: 0,
      cartaoBoolean: false,
      boletoBoolean: false,
      cartao: {
        bandeira: '',
        numero: '',
        nome: '',
        validade: '',
        ccv: '',
        parcela: '',
      },
      mpErros: [],
      pagamentoResponse: null,
      mp: null,
      cardForm: null,
      enviando: false,
      logo: process.env.VUE_APP_LOGO_PATH,
      site_name: process.env.VUE_APP_SITE_NAME,
    };
  },
  computed: {
    mpErroArray() {
      return [...new Set(this.mpErros)];
    },
    pagamentoStatus() {
      switch (this.pagamentoResponse?.status) {
        case 'approved':
          return 'alert-success';
        case 'in_process':
          return 'alert-info';
        case 'rejected':
          return 'alert-danger';
        default:
          return '';
      }
    },
  },
  methods: {
    ...mapActions([]),

    mercadoPagoForm() {
      const mp = new MercadoPago(
        'APP_USR-24a73b9b-e908-4dc3-a1e3-26e1ee3f4320',
        {
          locale: 'pt-BR',
        }
      );

      this.cardForm = mp.cardForm({
        amount: '100.5',
        iframe: true,
        form: {
          id: 'form-checkout',
          cardNumber: {
            id: 'form-checkout__cardNumber',
            placeholder: 'Número do cartão',
          },
          expirationDate: {
            id: 'form-checkout__expirationDate',
            placeholder: 'MM/YY',
          },
          securityCode: {
            id: 'form-checkout__securityCode',
            placeholder: 'Código de segurança',
          },
          cardholderName: {
            id: 'form-checkout__cardholderName',
            placeholder: 'Titular do cartão',
          },
          issuer: {
            id: 'form-checkout__issuer',
            placeholder: 'Bandeira',
          },
          installments: {
            id: 'form-checkout__installments',
            placeholder: 'Parcelas',
          },
          identificationType: {
            id: 'form-checkout__identificationType',
            placeholder: 'Tipo de documento',
          },
          identificationNumber: {
            id: 'form-checkout__identificationNumber',
            placeholder: 'Número do documento',
          },
          cardholderEmail: {
            id: 'form-checkout__cardholderEmail',
            placeholder: 'E-mail',
          },
        },
        callbacks: {
          onFormMounted: (error) => {
            if (error) {
              return console.warn('Form Mounted handling error: ', error);
            }
          },
          onSubmit: async (event) => {
            event.preventDefault();

            this.enviando = true;
            const {
              paymentMethodId: payment_method_id,
              issuerId: issuer_id,
              cardholderEmail: email,
              amount,
              token,
              installments,
              identificationNumber,
              identificationType,
            } = this.cardForm.getCardFormData();

            try {
              const resp = await http({
                method: 'POST',
                url: `fatura/${this.fatura.faturaId}/Pagamento`,
                headers: {
                  'Content-Type': 'application/json',
                },
                data: JSON.stringify({
                  token,
                  formaPagamentoId: payment_method_id,
                  valor: Number(this.valorTotalCartao),
                  parcela: Number(this.cartao.parcela),
                  descricao: `Pagamento da Fatura número ${this.fatura.codigo}`,
                  bandeira: payment_method_id,
                  cliente: {
                    email,
                    identificacaoTipo: identificationType,
                    identificacaoNumero: identificationNumber,
                  },
                }),
              });
              this.enviando = false;

              this.inicializar();
              this.pagamentoResponse = resp.data.data;
            } catch (error) {
              this.enviando = false;
              await this.cardForm.createCardToken();
              this.pagamentoResponse = {
                status: 'rejected',
                mensagem: error.response.data.Message,
              };
            }
          },
          onCardTokenReceived: async (errorData, token) => {
            this.mpErros = [];
            if (errorData) await this.cardForm.createCardToken();

            errorData?.forEach((f) => {
              const err = mpErros.find(
                (e) => e.codigo == f.code || f.message?.includes(e.codigo)
              );
              if (err) this.mpErros.push(err);
            });
            return token;
          },
        },
      });
    },

    download() {
      http({
        method: 'GET',
        url: `${DOWNLOAD_BOLETO_URL}fatura/${this.fatura.faturaId}/DownloadBoleto`,
        responseType: 'blob',
      }).then((resp) => {
        saveAs(resp.data, 'boleto.pdf');
      });
    },

    getFatura() {
      return new Promise((resolve, reject) => {
        http({
          url: `fatura/${this.faturaToken}`,
          method: 'get',
        }).then((resp) => {
          this.fatura = resp.data.data;
          this.itensFatura = this.fatura.itensFatura;
          this.dataVencimento = moment(this.fatura.dataVencimento).format(
            'DD/MM/YYYY'
          );

          resolve();
        });
      });
    },
    changeOpcaoPagamento(option) {
      if (option == 'boleto') {
        this.boletoBoolean = true;
        this.cartaoBoolean = false;
      }
      if (option == 'cartao') {
        this.boletoBoolean = false;
        this.cartaoBoolean = true;
      }
    },
    mudarParcela() {
      const opcao = this.fatura.opcoesParcelamento.find(
        (x) => x.parcela === this.cartao.parcela
      );
      this.valorTotalCartao = opcao.parcela * opcao.valorMensal.toFixed(2);
      this.valorTaxaCartao =
        this.valorTotalCartao - this.fatura.valorTotal.toFixed(2);
    },
    async inicializar() {
      this.faturaToken = this.$route.params.faturaToken;

      await this.getFatura();
      await this.mercadoPagoForm();

      if (
        this.fatura.formasPagamento.includes('Cartão de Crédito') ||
        (this.fatura.formasPagamento.includes('Cartão de Crédito') &&
          this.fatura.formasPagamento.includes('Boleto'))
      ) {
        this.changeOpcaoPagamento('cartao');
      } else if (
        !this.fatura.formasPagamento.includes('Cartão de Crédito') &&
        this.fatura.formasPagamento.includes('Boleto')
      ) {
        this.changeOpcaoPagamento('boleto');
      }
    },
  },
  mounted() {
    this.inicializar();
  },
  destroyed() {
    this.mercadoPagoForm();
  },

  filters: {
    formatDataHora(value) {
      return moment.utc(value).local().format('DD/MM/YYYY HH:mm');
    },
  },
};
</script>

<style scoped>
#form-checkout__issuer {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  text-align: center;
  height: 18px;
  font-size: 14px;
  background-color: #0783ae;
  color: #fff;
  font-weight: bold;
  max-width: 150px;
}

#nrCard {
  display: flex;
  justify-content: space-between;
}

.none-padding-margin {
  margin: 0;
  padding: 0;
}

.row-info {
  border-bottom: 1px dashed black;
  padding-bottom: 20px;
}

.row-title-pagamento {
  border-top: 1px solid black;
}

.body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol';
}

.nav-tabs {
  padding: 0%;
}

.nav-tabs li {
  padding: 0%;
}

.txt-end {
  text-align: right;
}
.btn-pagar {
  background-color: green;
  border-radius: 5px;
  color: white;
  margin-bottom: 60px;
}

label {
  color: #6c757d !important;
  margin-bottom: 0;
}

p {
  color: black;
  margin-top: 0;
  /* padding-bottom: 1rem; */
}

h5 {
  font-weight: 400;
  font-size: 1rem;
}

svg {
  width: 0.8rem;
  height: 1.3rem;
  margin-right: 1px;
}

table {
  height: 100%;
}

.inactive {
  border: none;
  border-bottom: 1px solid #d7dade !important;
}

.nav-link {
  cursor: pointer;
}

::placeholder {
  font-size: 14px;
}

@media (min-width: 360px) {
  .fatura {
    width: 100%;
  }

  .btn-pagar {
    width: 90%;
  }
}

@media (min-width: 360px) {
  @media (min-height: 640px) {
    .row-detalhes {
      height: 44vh;
    }

    .cartao {
      height: 92vh;
    }
    .boleto {
      width: 360px !important;
    }
  }

  @media (min-width: 800px) {
    .row-detalhes {
      height: 19vh;
    }

    .cedente {
      visibility: visible;
    }

    .btn-pagar {
      width: 100%;
    }

    .boleto {
      width: 660px !important;
    }
  }

  @media (min-width: 1280px) {
    .row-detalhes {
      height: 26vh;
    }
    .boleto {
      width: 660px !important;
    }
  }
}

.boleto {
  width: 100%;
  height: 760px;
  margin: 40px auto;
}

.boleto iframe {
  width: 100%;
  height: 760px;
}
</style>
